import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Generator from "../components/generator"
import Seeder from "../components/seeder"
import {setPitch} from "../state/app"
import { Link } from "gatsby"
import {connect} from "react-redux"
import { navigate } from "gatsby"
import Advanced from "../components/advanced"

class PitchGenerator extends React.Component {

  state = {
    currentSentance : null,
    mode:0,
    seeTools : false,
    pitch : "",
    know:true
  }


  nextMode(value) {
    let mode = value

    this.setState({
      mode
    })
  }

  end() {
    this.savePitch();
    navigate("/storyboardBuilder");
  }

  savePitch() {
    this.props.setPitch(this.state.pitch);
  }

  whichTool(mode) {
    if (mode == 0)
      return <Seeder/>
    if (mode == 1)
      return <Generator/>
    if (mode == 2)
      return <Advanced/>
  }

  renderContent() {
    if(this.state.know === null) {
      return (
        <div style={{textAlign:"center",marginTop:30}} >
          <p>
            Tu as déjà une idée de pitch ?
          </p>
          <div style={{marginTop:10}}>
            <button style={{marginRight:20}} className="btn" onClick={() => {this.setState({know:true})}}>oui</button>
            <button  className="btn" onClick={() => {this.setState({know:false})}}>non</button>
          </div>
        </div>
      )
    }

    const note = (
      <div>
        <div style={{marginBottom:10, marginTop:10}}>
          <h4>
            Votre pitch
          </h4>
          <textarea style={{ marginTop:0, width:'99%', minHeight:100}} value={this.state.pitch} onChange={(e) => {this.setState({pitch:e.target.value})}}></textarea>
        </div>

        <div style={{clear:"both"}}></div>
        {
          (
            (
              <div style={{marginBottom:10, textAlign:"center"}}>
                {/**<Link to="/storyboardBuilder"></Link>**/}
                <button onClick={() => this.end()} className="btn" disabled={!this.state.pitch}>
                  ÉTAPE SUIVANTE
                </button>
              </div>
            )
          )
        }

      </div>
    )

    const helperZone = (
      <div>
        {
          this.state.seeTools ?
          <div className='tool-container'>
            <h3>Outils d'aide à la création</h3>
            <div>
              {
                (this.state.mode === 0) ?
                <Seeder />:
                <Generator />
              }
            </div>
            <p style={{textAlign:"right", display:"none"}}>
              <small>
                <span className='link' onClick={() => { this.nextMode() }}>Changer d'outils</span>
              </small>
            </p>

          </div>
        :null
        }
      </div>
    )

    const result = []
    if(this.state.know) {
      result.push(note);
      //result.push(helperZone);
    } else {
      result.push(helperZone);
      //result.push(note);
      if(!this.state.seeTools)
        this.setState({seeTools:true})
    }

    return result;
  }

  render() {
    return (
      <Layout>

        <h2>
          Le Pitch
        </h2>

        <p style={{marginBottom:30}}>
          Le pitch est l'histoire résumée en quelques phrases. Tout au long de la création du jeu, on doit garder en tête le pitch afin de créer une aventure qui a du sens pour le joueur.
          <span className="exemple">
            <strong>EXEMPLE</strong><br/>
            Un professeur de l’université de Bordeaux Montaigne à disparu lors d’un voyage de recherche en 1870. Sa disparition inquiète, il était censé uniquement observer la construction de la statue des 3 grâces. Le joueur doit le retrouver et le ramenez sain et sauf.
          </span>
        </p>

        {
          this.renderContent()
        }

        <div id="right-part">
          {(
            !this.state.seeTools &&
              <div style={{textAlign:"center"}}>
                <p style={{marginBottom:15, textTransform:"uppercase"}}>Besoin d'aide ?</p>
                <div onClick={() => {this.setState({seeTools:true})}} className="btn inverse">
                  Voir les outils d'aide à la création
                </div>
              </div>
          )}
          {
            this.state.seeTools &&
            <div className="gen-container">
              <div className='tool-container'>
                <h3 style={{display:"none"}}>Outils d'aide à la création</h3>
                <div className="tab-container">
                  <h4 className={(this.state.mode === 0)?"active":"btn"} onClick={() => { this.nextMode(0) }}>Graine d'idée</h4>
                  <h4 className={(this.state.mode === 1)?"active":"btn"} onClick={() => { this.nextMode(1) }}>Générateur de Pitch Atlantide</h4>
                  <h4 className={(this.state.mode === 2)?"active":"btn"} onClick={() => { this.nextMode(2) }}>Connecteur de mots</h4>
                </div>
                <div>
                  {
                    this.whichTool(this.state.mode)
                  }
                </div>
                <p style={{textAlign:"right", display:"none"}}>
                  <small>
                    <span className='link' >Changer d'outil</span>
                  </small>
                </p>
              </div>
            </div>
          }
        </div>
      </Layout>
    )
  }
}

export default connect(
  state => {
    return {}
  },
  dispatch => {
    return {
      setPitch : (content) => dispatch(setPitch(content))
    }
  }
)
(PitchGenerator)
