import React, {Component} from "react"

const getRandomArrayElement = (arrayE) => {
  return arrayE[Math.floor(Math.random() * arrayE.length)]
}

const getRandomElement = (min = 0, max = 99999999) => {
  return min + Math.floor(Math.random() * max)
}

const randomInStrings = (...strings) => {
  return getRandomArrayElement(strings);
}

const removeItem = (items, i) => {
  items.splice(i, 1);
  return [...items]
}

class Generator extends Component {

  state = {
    personnages : [],
    newPersonnage : "",
    trams : [],
    useAtlantide : true,
  }

  getTime() {
    return parseInt(this.state.timeMin) + Math.floor( Math.random() * ( parseInt(this.state.timeMax) - parseInt(this.state.timeMin)  ) )
  }

  getPersonnage() {
    const personnagesAtlantide = [
      "un agent Atlantide",
      "un historien du futur",
    ]

    let personnages = [
      ...this.state.personnages
    ];

    if(this.state.useAtlantide) {
      personnages = [
        ...personnagesAtlantide,
        ...personnages
      ]
    }


    return getRandomArrayElement(personnages)
  }

  getBadGuys() {
    const badGuysAtlantide = [
      "Syndicate",
      "Sigma"
    ];

    let badGuys = []
    if(this.state.useAtlantide) {
      badGuys = [
        ...badGuysAtlantide,
        ...badGuys
      ]
    }

    return getRandomArrayElement(badGuys)
  }

  getTrame() {
    const trams = [
      () => { // TENTATIVE D'ASSASSINAT
         return "Le joueur doit contrer une tentative d'assassinat sur "+this.getPersonnage()+".";
      },

      () => { // VOYAGE NON AUTORISÉ
        const isAmnesic = Math.random();
        let pitch = "Un individue a été interpellé au retour d'un voyage temporelle";
        if(isAmnesic)
          pitch += ", nous n'avons rien pu en tirer car il a pris un sérume d'amnésie lors de l'interpéllation";
        pitch += "."

        /*
        pitch += " La conclusion sera qu'il a essayé ";
        pitch += randomInStrings(
          "de détruire quelque chose",
          "de voler quelque chose",
          "d'ajouter quelque chose à l'histoire"
        );
        pitch += ".";
        */

        return pitch;
      },

      () => { // TAUPE ?
        let pitch = "Un agent Atlantide est suspecté de trahir l'agence.";

        /*pitch += " La conclusion est ";
        pitch += randomInStrings(
          "qu'il est coupable",
          "qu'il est tombé amoureux d'une personne lors de son voyage",
          "qu'il est tombé amoureux de l'époque lors de son voyage",
          "que "+this.getBadGuys()+" le fait chanter"
        );
        pitch += ".";*/

        return pitch;
      }
    ]

    return getRandomArrayElement(trams)();
  }

  addTram() {
    this.setState({
      //trams:[...this.state.trams, this.getTrame()]
      trams:[this.getTrame()]
    })
  }

  componentDidMount() {
    // this.addTram();
  }


  render() {
    return (
      <div className="gen-container">
        <p>
          Ce générateur te donne un pitch préconçu par un algorithme, qu'il te faut compléter et adapter en fonction de ton contexte. Il a été conçu en premier lieu pour correspondre aux besoins de <a href='https://intercom.help/atlantide-sas/fr/collections/2363576-atlantide'>l'univers Atlantide.</a>
        </p>

        {
          this.state.trams.map((tram, id) => {
            return (
              <p style={{textAlign:"center", margin:10}} onClick={() => this.setState({trams : removeItem(this.state.trams, id)})}>
                <strong>"{tram}"</strong>
              </p>
            )
          })
        }
        <div  style={{textAlign:"center", marginBottom:20, marginTop:20}}>
          <button onClick={() => {this.addTram()}} className="btn inverse">Nouveau Pitch</button>
        </div>

        <div>
          <p  onClick={() => this.setState({useAtlantide : !this.state.useAtlantide})} style={{display:"none"}}>
            {
              (this.state.useAtlantide) ? "[V]" : "[ ]"
            }
            Utiliser l'univers Atlantide
          </p>

          <h4>Personnages (historique ou fictif)</h4>
          {
          this.state.personnages.length === 0 &&
            <p>
              Aucun personnage ajouté.
            </p>
          }
          <ul>
          {
            this.state.personnages.map((personnage, id) => {
              return (
                <li>
                  {personnage}
                  <span className="delete-cross btn inverse small" style={{marginLeft:10}}  onClick={() => this.setState({personnages : removeItem(this.state.personnages, id)})}>
                    X
                  </span>
                </li>
              )
            })
          }
          </ul>
          <div style={{display:"flex", alignItems:"center"}}>
            <label style={{marginRight:10, textTransform:"uppercase"}}>Nouveau personnage </label>
            <input value={this.state.newPersonnage} onChange={(e) => {this.setState({newPersonnage : e.target.value})}} style={{marginRight:10}} />
            {this.state.newPersonnage ? <button className="btn inverse small" onClick={() => {this.setState({personnages: [...this.state.personnages, this.state.newPersonnage], newPersonnage:""})}}>Ajouter</button>: null}
          </div>
        </div>

      </div>
    )
  }
}

export default Generator
