import React, {Component} from "react"

const getRandomArrayElement = (arrayE) => {
  return arrayE[Math.floor(Math.random() * arrayE.length)]
}

const deleteArrayElement = (arrayE, element) => {
  const index = arrayE.indexOf(element);
  const copy = [].concat(arrayE)
  if (index > -1) {
  copy.splice(index, 1);
  }
  return copy
}

const getRandomElement = (min = 0, max = 99999999) => {
  return min + Math.floor(Math.random() * max)
}

const randomInStrings = (...strings) => {
  return getRandomArrayElement(strings);
}

const removeItem = (items, i) => {
  items.splice(i, 1);
  return [...items]
}

class Advanced extends Component {

  state = {
    personnages: [],
    newPersonnage: "",
    words: [],
    useAtlantide: true
  }

  word = {
    subj: [
      "robot",
      "agent",
      "voleur",
      "vieille dame",
      "hackeur",
      "microbe",
      "informateur",
      "journaliste",
      "etudiants",
      "collectionneur",
      "cartel",
      "scientifique",
      "prêtre",
      "seigneur",
      "savant",
      "assassin",
      "détective",
        ],

    verb: [
      "identifier",
      "oublier",
      "casser",
      "échanger",
      "traduire",
      "adhérer",
      "chasser",
      "préserver",
      "assembler",
      "téléphoner",
      "convaincre",
      "engager",
      "voler",
      "assassiner",
      "émigrer",
      "recueillir",
      "inspecter",
      "lire",
      "cacher",
      "penser",
    ],

    name: [
      "monarchie",
      "embuscade",
      "conversation",
      "boussole",
      "labyrinthe",
      "domination",
      "corps",
      "pistolet",
      "censure",
      "industrie",
      "mensonge",
      "cercueil",
      "prédateur",
      "église",
      "maison",
      "diamant",
      "potion",
      "esclave",

    ],

    adj: [
      "critique",
      "louche",
      "mort",
      "heureux",
      "innocent",
      "triste",
      "cassé",
      "oublié",
      "perdu",
      "masqué",
      "détruit",
      "macabre",
      "artificiel",
      "détraqué",
      "fou",
    ]
  }

  getTime() {
    return parseInt(this.state.timeMin) + Math.floor(Math.random() * (parseInt(this.state.timeMax) - parseInt(this.state.timeMin)))
  }
  getRandomWords() {
    const props = Object.keys(this.word);
    return (
      props.map((prop,id) => {
        return { 'value':getRandomArrayElement(this.word[prop]), prop }
      })
      )
  }

  addWords() {
    this.setState({
      words: this.getRandomWords()
    })
  }
changeWord(id,w) {
  const words =  this.state.words
  const toDelete = deleteArrayElement(this.word[w.prop],w.value)
  words[id]['value'] = getRandomArrayElement(toDelete)
  this.setState({
    words
  })
}


  render() {
    return (<div className="gen-container">
      <p>
        Cet outil t'aide à stimuler ta créativité au maximum pour t'aider à avoir un pitch inédit. Ton but est de créer un pitch qui contient les 4 mots générés. Tu peux changer l'ensemble des mots ou seulement un seul des mots si l'inspiration te manque.
      </p>

      <div style={{
          textAlign: "center",
          marginBottom: 20
        }}>
        {
          (this.state.words.length == 0) && <button onClick={() => {
              this.addWords()
            }} className="btn inverse" style={{marginTop:20}}> Commencer</button>
        }
        </div>
        <div className="words-container">
          {
          this.state.words.map((word,id) => {
            return (<p className="words cliquable"  onClick={() => {this.changeWord(id,word)}}>
              {word.value}
              </p>)
          })
        }
        </div>
      <div style={{
          textAlign: "center",
          marginBottom: 20
        }}>
        { this.state.words.length > 0 &&
        <div>
          <button onClick={() => {
              this.addWords()
            }} className="wordsgen-button btn">Générer d'autres mots
          </button>
          <hr style={{marginBottom : 10,
                      marginTop : 20,
                      borderColor: 'white',
                      opacity : 1,
                      borderWidth: 0.5,

                    }}/>
          <p className="inline-hint">
            <img src={require("../images/bulb-outline.svg")} id="hint"/>
            Tu peux cliquer sur un mot pour le changer
          </p>
        </div>
        }
      </div>
    </div>)
  }
}

export default Advanced
