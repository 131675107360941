import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Generator from "../components/generator"
import { Link } from "gatsby"

class Seeder extends React.Component {

  state = {
    currentSentance : null,
  }

  sentances = [
    "Rencontre son amour de jeunesse par hasard dans la rue.",
    "Son meilleur ami vient d’avoir un grave accident de voiture.",
    "L’avocat chargé de son divorce a disparu sans laisser de traces.",
    "Sa belle-mère a subi une opération de chirurgie esthétique.",
    "Un ours est dans le jardin.",
    "Remarque que le facteur a un revolver dans son sac.",
    "Le caniche a une crise d’épilepsie.",
    "Se fait arrêter par la police alors que la voiture n’a plus de contrôle technique.",
    "Perd son pot d’échappement sur l’autoroute.",
    "Le voisin psychopathe hurle sur sa terrasse.",
    "Un octogénaire aux cheveux longs danse nu dans la rue.",
    "A oublié où il a garé sa voiture.",
    "Découvre un petit sachet de marijuana dans la chambre de son fils.",
    "Surprend la femme de ménage en train de boire de son cognac préféré.",
    "Son médecin lui apprend qu’il ne pourra jamais avoir d’enfants.",
    "Décide d’adopter un enfant venant d’Haïti.",
    "Voit sa femme dans un restaurant avec le professeur de mathématiques de sa fille.",
    "S’assoit sur ses lunettes en montant dans la voiture.",
    "Ferme la voiture et oublie la clé dans le coffre.",
    "Se casse un bras en tombant d’un pommier.",
    "Son beau-frère qu’il déteste a vomi sur la moquette.",
    "Doit accompagner son chef à Tokyo.",
    "Recommence à fumer après 22 ans d’abstinence.",
    "Prend par mégarde la sacoche d’un inconnu dans le train au lieu de la sienne.",
    "Trouve un porte-monnaie avec plus de 500 euros dans le métro.",
    "Témoin d’une agression dans une boutique du quartier.",
    "Voit son beau-père, veuf de longue date, sortir d’une bijouterie un petit paquet à la main.",
    "Sa voisine ne lui dit plus bonjour.",
    "Remarque que le livreur de pizza fait un clin d’œil à sa fille.",
    "Découvre un grand coffre en bois fermé à clé dans le grenier du gîte rural.",
    "Son avion est détourné.",
    "Renverse son verre de vin sur le pantalon d’un collègue marocain de passage.",
    "Refuse de fumer du haschich avec son collègue marocain.",
    "Trouve la photo d’une belle inconnue dans la poche de son veston.",
    "Gagne un voyage à Dubaï.",
    "Son fils se rase le crâne et se fait tatouer le visage.",
    "Le nouveau voisin, un boucher ivoirien, a onze enfants.",
    "Sa femme se convertit au judaïsme.",
    "Remarque qu’il a rapetissé de trois centimètres.",
    "Sa sœur veut entrer au couvent.",
    "Reçoit un paquet de Chine.",
    "La nouvelle secrétaire rougit en le voyant et évite son regard.",
    "Écrase le chat de la voisine en sortant la voiture du garage.",
    "Découvre une chouette clouée à la porte de la grange.",
    "Le téléphone sonne régulièrement en plein milieu de la nuit.",
    "Son chef part en vacances et lui confie la garde de son Pitt Bull Terrier « Benito ».",
    "Un croupier tombe amoureux d’une joueuse compulsive.",
    "Une panne de courant mystérieuse intervient uniquement dans le domicile du personnage.",
    "Découvre un nouveau sentier au fond de son jardin.",
    "Découvre un matin que tous ses goûts alimentaires habituels sont inversés.",
    "Découvre qu’elle n’est pas ce qu’elle croit être.",
    "Danse éternellement, au centre de la spirale.",
    "Vois la réalité telle qu’elle est, d’une tristesse intense.",
    "Découvre que son pipi fait fondre les objets.",
    "Découvre que son jardinier est un agent de la CIA et que l’Etat l’espionne.",
    "Est persuadé(e) qu’il (elle) est le centre du monde, que tous les habitants de la planète ne sont que des acteurs (ou personnes programmées) de sorte à « évaluer ses capacités à réaliser sa vie ».",
    "Découvre qu’il est victime d’un complot.",
    "Découvre que ses pires cauchemars deviennent réalité.",
    "Se lève un beau jour, complétement amnésique.",
    "Découvre qu’il a un don Médiumnique qui lui permet de parler avec les morts.",
    "Découvre que sa mère est le premier amour du président du pays.",
    "Cette nuit durant un rêve, Dieu, vêtu d’un pyjama à cœurs roses, vous a dit qu’à partir de demain les règles du monde vont changer d’une certaine manière.",
    "Un muet doit garder un perroquet bègue.",
    "Depuis 6 mois, il a perdu 4 dents en dormant, retrouvées à chaque fois dans son oreiller, et remarqué que 4 adresses de ses relations s’étaient effacées de son agenda mystérieusement…",
    "Redécouvre la vie après un accident qu’il l’a rendu aveugle.",
    "Au moment où il franchit le seuil de la vieille boutique poussiéreuse et hors d’âge du cordonnier, il crut apercevoir l’ombre d’un tricorne fuyant rapidement sur le mur de l’arrière-boutique… S’approchant, il vit qu’elle était déserte et dépourvue d’issue autre que par la porte qu’il venait de franchir…",
    "Un loup fait un strip-tease sur le comptoir du bar le plus en vogue de New York.",
    "Le petit chaperon rouge raconte un conte horrifique à un bébé vampire assoiffé de sang.",
    "Le barman dont elle est amoureuse est pilote de voiture de course clandestin et traqué par la police.",
    "Tic tac, tic tac fait l’horloge du monde, et pourtant, et pourtant le temps n’existe pas.",
    "La personne dont le personnage est amoureux est assassinée.",
    "Il lit le générateur d’idées pour écrire un roman… et si l’incipit était une page blanche ? Boulot de paresseux ou véritable créativité ?",
    "Plusieurs fois par semaine, un personnage mystérieux lui confie une mission « impossible » à réaliser sous 24h, en échange de sa liberté.",
    "Par terre, dans la rue, elle trouve un portefeuille., Dedans, bien à l’abri dans une pochette en plastique, une fleur séchée d’un bleu profond qu’elle ne connait pas, et pas d’identité du propriétaire… elle cherche, cherche, la fleur n’existe pas sur terre…",
    "Elle rencontre une vieille amie au coin d’une rue qu’elle n’a pas revue depuis plus de 10 ans. Pourquoi a-t-elle disparu toutes ces années ?",
    "Formée aux combats par les services secrets israéliens, Lucia est embauchée dans une maison de retraite pour anciens agents secrets…",
    "Un groupe de jeunes aventuriers, en explorant une grotte, trouvent un passage vers une dimension parallèle et sont projetés dans le monde des bisounours. Mais ceux-ci sont méchants et nécrophages…",
    "Un ange, un vrai, vient de tomber du ciel, juste devant moi.",
    "Mais que dire de celui qui se réveille un matin… seul au monde.",
    "Évitant à tout prix une tâche, il s’y confronte avec difficultés morales.",
    "Un petit chien passe de fourrière en fourrière et trouve finalement le bonheur auprès d’un enfant handicapé.",
    "Un homme se réveille un matin et s’aperçoit qu’il a six doigts à chaque main.",
    "Péripéties de vacances d’une famille avec 6 enfants.",
    "Un tableau de maître passe de génération en génération.",
    "Les gens croisent souvent ce SDF, alcoolique, ils le méprisent, mais tout le monde ignore qu’il était agent secret avant de perdre sa femme.",
    "Nathalie est enceinte… pourtant elle n’a rien fait !",
    "Un peintre rentre dans ses tableaux.",
    "Un détail dans un tableau attire le regard de Caroline. Un personnage aux yeux tristes sur  fond de paysage qui lui rappelle un endroit qu’elle a connu, enfant. Elle va essayer de retrouver cet endroit et peut-être le personnage ?",
    "Manuel veut changer le cadre d’un ancien tableau trouvé dans une brocante  et y découvre, caché,  un petit papier plié en quatre sur lequel sont écrits quelques mots à moitié effacés…",
    "Il est le seul adulte restant sur Terre. Le reste du monde est peuplé par des enfants de moins de 12 ans.",
    "Un écrivain s’est amusé à créer le profil Facebook de son personnage préféré. Un jour, il remarque un message posté dont il ne se souvient pas.",
    "Son père est un lycanthrope et sa mère est un vampire. Comment être une adolescente comme les autres quand ses parents ne sont pas comme tout le monde ?",
    "La vie de chaque célibataire qui croise sa route bascule d’une manière ou d’une autre.",
    "Il se réveille un matin en découvrant qu’il ne parle qu’en proverbes et maximes.",
    "Les objets deviennent vivants et échappent à tout contrôle humain.",
    "Le chien de ma petite amie a avalé la bague de fiançailles que je voulais lui offrir.",
    "Mes toilettes sont encore bouchées…",
    "Ma charmante voisine me demande d’arroser ses plantes en son absence.",
    "Mon nouveau collègue et voisin de bureau sent le viandox, miam!",
    "Le tabac-presse en bas de chez moi est fermé. C’est ennuyeux.",
    "Le petit copain de ma fille est champion de France de crachat de noyaux de cerises.",
    "Un fonctionnaire qui s’embête dans sa triste vie décide de singer le « Fight Club ». Ce qui, sur le papier, avait l’air plutôt sexy vire au pathétique quand les José, Luc et Vincent remplacent Brad, Edward ou Connor.",
    "Un SDF joue du violon sous ma fenêtre.",
    "J’ai failli renverser une femme sur une petite route boueuse, en m’approchant, elle me demande de l’aide, elle est pourchassée. C’est une fée…",
    "Vous êtes sur le point de mourir, un homme se présente comme étant l’avocat des morts.",
    "Vous croisez un vampire végétarien.",
    "Après un contrôle médical de routine, il découvre qu’il doit trouver une personne pour lui donner un rein.",
    "Une bande de potes jouent sur un jeu en ligne depuis deux ans. Ils décident de se rencontrer dans la réalité.",
    "Découvre que toute sa vie est déjà écrite et qu’il ne peut rien y changer.",
    "Un jeune homme rebelle rencontre un vieux sage un peu fou et une amitié naît.",
    "Il a envie d’être amoureux, mais il ne sait comment aimer.",
    "Elle/Il découvre que son/sa meilleur(e) ami(e) est un fantôme mort depuis des années.",
    "Découvre que sa parfaite femme a deux autres relations amoureuses.",
    "Se réveille un matin et découvre que tous les gens de la ville sont figés, recouverts d’une fine pellicule de givre, sans doute morts.",
    "Un jeune écrivain commence à coucher sur le papier l’histoire d’un monde imaginaire. Sans s’en rendre compte, au fur et à mesure, le paysage qu’il décrit prend place autour du personnage…",
    "Il connait un vieux qui a connu un aïeul qui connaissait un ancien qui a connu un royaume qu’on appelait jadis… Brume"
  ]

  nextIdeaSeed() {
    let currentSentance = Math.floor(Math.random() * this.sentances.length);
    this.setState({
      currentSentance
    })
  }
  
  render() {
    return (
      <div  className="gen-container">
        <p style={{marginBottom:20}}>
          C’est une astuce qui vient du site <a target="_blank" href='http://ecrire-un-roman.com/'>ecrire-un-roman.com</a>.
          L’idée est de vous présenter un ensemble de phrases assez vagues, qui devraient réveiller votre créativité. Il faut simplement lire à son rythme et voir si quelque chose vient.
        </p>

        <div style={{minHeight:100}}>
          {(this.state.currentSentance !== undefined && this.state.currentSentance !== null) ?
          <div style={{marginBottom:20, textAlign:"center"}}>
            <p style={{textAlign:"center"}}>
              <strong>
                "{this.sentances[this.state.currentSentance]}"
              </strong>
            </p>

            <button onClick={() => {this.nextIdeaSeed()}} className="btn inverse" style={{marginTop:10}}>
              Phrase suivante
            </button>
          </div>
          :
          <div style={{marginTop:20, textAlign:"center"}} >
            <button onClick={() => {this.nextIdeaSeed()}} className="btn inverse">
              Commencer
            </button>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default Seeder
